import React from 'react'

function Panel(props) {
  return (
    <>
      <div 
        className={`panel ${props.state ? 'is-open' : ''}`}
        style={{backgroundColor: props.type.color}}>
        <div className="panel__close">
          <button onClick={() => props.close()}>Close</button>
        </div>
        {props.type.content}
      </div>
      <div 
        className="panel__overlay"
        onClick={() => props.close()}></div>
    </>
  )
}

export default Panel