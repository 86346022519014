import React, { Component } from 'react'

class Accordion extends Component {
  constructor(props) {
    super(props)

    this.state = {
      active: false
    }
  }

  setActive = () => {
    this.setState(({ active }) => ({ active: !active }))
    console.log(this.state.active)
  }

  render() {
    let { activeIndex } = this.state
    let accordionContent = React.Children.map(this.props.children, (child, index) => {
      return (
        <div className={`accordion__section ${this.state.active ? 'is-active' : ''}`}> 
          <h2
            className="accordion__section-heading"
            onClick={() => this.setActive()}>
            <span>{child.props.label}</span>
            {this.state.active ? 
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="2" viewBox="0 0 20 2">
                <g transform="translate(-1240.5 -162.5)">
                  <line y2="20" transform="translate(1260.5 163.5) rotate(90)" fill="none" stroke="#101010" strokeWidth="2"/>
                </g>
              </svg> : 
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                <g transform="translate(-1240.5 -153.5)">
                  <line y2="20" transform="translate(1250.5 153.5)" fill="none" stroke="#101010" strokeWidth="2"/>
                  <line y2="20" transform="translate(1260.5 163.5) rotate(90)" fill="none" stroke="#101010" strokeWidth="2"/>
                </g>
              </svg>
            }
          </h2>
          <div className="accordion__section-content">
            <article label="Founding Team">
              <div>
                <h3>Marisa Ma</h3>
                <p>Marisa strives for a paradigm shift of responsible practices through renewed standards of what it is to be a conscience-led brand and the operation of them.</p>
                <p>Her professional experience began in entertainment  PR and communications in New York City and continued in journalism for bi-lingual publications in Italy. During this period, she delved into business development for fashion and lifestyle projects. Fifteen years later, she co-founded Atelier &amp; Repairs, a fully vertical business model based on principles of the circular economy. Marisa has contributed to various sustainability focused publications including The Ellen MacArthur Foundation's Make Fashion Circular case studies.</p>
                <p>Born in Hong Kong, Marisa spent her formative years in California before studying at New York University. She currently lives in Los Angeles with her husband and son.</p>
              </div>
              <div>
                <h3>Kate Montana</h3>
                <p>Kate is driven by a mission to bring positive, holistic, and sustainable change to our production and consumption habits through inspiring open information and knowledge exchange.</p>
                <p>With experience in hospitality and lifestyle copywriting and retail marketing and activations, Kate joined the founding team of Atelier &amp; Repairs in 2015 focusing on the brand narrative while driving the company's commercial reach to key global retailers and partners.</p>
                <p>Kate was born in California and earned a BA in Global Communications with a concentration in Writing for the Media from the American University of Rome, followed by projects in Spain and New York City. She currently lives in Los Angeles.</p>
              </div>
            </article>
          </div>
        </div>
      )
    })
    return (
      <>
        <div className="accordion">
          {accordionContent}
        </div>
      </>
    )
  }
}

export default Accordion